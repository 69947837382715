"use client";

import { useState, useEffect } from "react";
import Cookies from "js-cookie";

const CookieBanner = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const consent = Cookies.get("cookieConsent");
    if (!consent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    Cookies.set("cookieConsent", "accepted", {
      expires: 365,
      sameSite: "strict",
    });
    setIsVisible(false);
    enableTechnicalCookies();
    enableStatisticalCookies();
  };

  const handleDecline = () => {
    Cookies.set("cookieConsent", "declined", {
      expires: 365,
      sameSite: "strict",
    });
    setIsVisible(false);
    console.log("Cookies declined");
  };

  const enableTechnicalCookies = () => {
    // Example of storing a session token
    const sessionId = "your-dynamic-session-id"; // Replace with actual session ID logic
    const csrfToken = "your-dynamic-csrf-token"; // Replace with actual CSRF token logic

    Cookies.set("session", sessionId, {
      expires: 1,
      sameSite: "strict",
      secure: true,
      httpOnly: true,
    });
    Cookies.set("csrfToken", csrfToken, {
      expires: 1,
      sameSite: "strict",
      secure: true,
      httpOnly: true,
    });
    console.log("Technical cookies enabled");
  };

  const enableStatisticalCookies = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", "G-0ZGSV9SG5L", {
      anonymize_ip: true,
    });

    Cookies.set("analyticsConsent", "accepted", {
      expires: 365,
      sameSite: "strict",
      secure: true,
    });
    console.log("Statistical cookies enabled");
  };

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 p-4 bg-gray-800 text-white text-center z-50">
      <h2 className="text-xl mb-4">Cookie-Einwilligung</h2>
      <p className="mb-4">
        Diese Website verwendet notwendige Cookies, um Ihr Erlebnis zu
        verbessern. Durch die Nutzung unserer Website stimmen Sie der Verwendung
        dieser Cookies zu. Statistische Cookies helfen uns, die Website zu
        verbessern und Inhalte anzupassen.
      </p>
      <button
        onClick={handleAccept}
        className="bg-slate-500 text-slate-200 px-4 py-2 rounded mr-2"
      >
        Akzeptieren
      </button>
      <button
        onClick={handleDecline}
        className="bg-slate-500 text-slate-200 px-4 py-2 rounded"
      >
        Ablehnen
      </button>
    </div>
  );
};

export default CookieBanner;
