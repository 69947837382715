"use client";

import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import AgeVerificationModal from "./AgeVerificationModal";
import CocktailCard from "./CocktailCard";

const AgeVerificationWrapper = ({ cocktail }) => {
  const [isVerified, setIsVerified] = useState(false);
  const [isBot, setIsBot] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const botPatterns = [
      "googlebot",
      "bingbot",
      "slurp",
      "duckduckbot",
      "baiduspider",
      "yandexbot",
      "sogou",
      "exabot",
      "facebot",
      "ia_archiver",
    ];

    if (botPatterns.some((bot) => userAgent.includes(bot))) {
      setIsBot(true);
    } else {
      const verified = Cookies.get("ageVerified");
      if (verified) {
        setIsVerified(true);
      }
    }
  }, []);

  const handleVerify = (status) => {
    if (status) {
      Cookies.set("ageVerified", "true", { expires: 7 });
      setIsVerified(true);
    }
  };

  if (isBot || isVerified) {
    return <CocktailCard {...cocktail} />;
  }

  return <AgeVerificationModal onVerify={handleVerify} />;
};

export default AgeVerificationWrapper;
