import { useState } from "react";
import Cookies from "js-cookie";

const AgeVerificationModal = ({ onVerify }) => {
  const [birthYear, setBirthYear] = useState("");
  const [message, setMessage] = useState(
    "ICH BIN MINDESTENS 18 JAHRE ALT. Unter den Rezepten sind viele Cocktails, die Alkohol enthalten. Da wir von 'Cocktail-des-Tages' den Jugendschutz sehr ernst nehmen, bitten wir Sie anzugeben, ob Sie bereits 18 Jahre alt sind."
  );

  const handleVerify = () => {
    const currentYear = new Date().getFullYear();
    const age = currentYear - parseInt(birthYear);
    if (age >= 18) {
      onVerify(true);
    } else {
      setMessage(
        "Sie sind unter 18 Jahren. Bitte besuchen Sie https://www.kenn-dein-limit.de/"
      );
      setTimeout(() => {
        window.location.href = "https://www.kenn-dein-limit.de/";
      }, 5000);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white text-slate-700 p-6 rounded-lg shadow-lg text-center">
        <h2 className="text-2xl font-bold mb-4">Altersprüfung</h2>
        <p className="mb-4">{message}</p>
        {message.includes("ICH BIN MINDESTENS 18 JAHRE ALT") && (
          <>
            <input
              type="number"
              value={birthYear}
              onChange={(e) => setBirthYear(e.target.value)}
              className="border rounded p-2 mb-4 w-full"
              placeholder="YYYY"
            />
            <button
              onClick={handleVerify}
              className="bg-slate-700 text-slate-100 px-4 py-2 rounded"
            >
              Verify
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default AgeVerificationModal;
